var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "vjs-languages-menu-item vjs-menu-item" },
    [
      _c("KRadioButton", {
        ref: "kRadioButton",
        attrs: {
          name: "languages-menu-item",
          label: _vm.label,
          value: 1,
          currentValue: _vm.selected ? 1 : 0,
        },
        on: {
          change: function ($event) {
            return _vm.setLanguage(_vm.value)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            _vm.setLanguage(_vm.value), _vm.$emit("hide")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }